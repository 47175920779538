/* Combined Sidebar.css and App.css */
* {
    box-sizing: border-box;
    text-align: center;
}
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 200px; /* Adjust width as needed */
    background-color: rgba(51, 51, 51, 0.8); /* Adjust background color and opacity as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px; /* Adjust padding as needed */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
}
.sidebar:hover {
    background-color: rgba(51, 51, 51, 0.95); /* Adjust hover background color and opacity as needed */
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    padding: 10px;
    color: #fff; /* Adjust text color as needed */
    cursor: pointer;
    position: relative; /* Required for pseudo-element positioning */
}

.sidebar ul li:before,
.sidebar ul li:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px; /* Adjust thickness of the lines as needed */
    background-color: #fff; /* Adjust line color as needed */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition */
}

.sidebar ul li:hover:before,
.sidebar ul li:hover:after {
    opacity: 1; /* Show lines on hover */
}

.sidebar ul li:before {
    left: -6px; /* Position the left line */
}

.sidebar ul li:after {
    right: -6px; /* Position the right line */
}

.sidebar ul li.active:before,
.sidebar ul li.active:after {
    opacity: 1; /* Show lines for active item */
}

.sidebar li {
    margin-bottom: 10px;
}

.sidebar li.active {
    font-weight: bold;
}
