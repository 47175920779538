.password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .password-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .password-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 1rem;
    width: 20rem;
    margin-bottom: 1rem;
  }
  
  .password-input:focus {
    outline: none;
    box-shadow: 0 0 2px 1px #377f7e;
  }
  
  .password-button {
    background-color: #377f7e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .password-button:hover {
    background-color: #2f6a62;
  }
  
  .password-error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }